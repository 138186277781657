<template>
	<div :style="inviteBg" style="justify-content: center;">
		<Header :title="$t('home.invite')"></Header>
		<div :style="qrBg"  @click="copyUrl" style="justify-content: center;">
			<vue-qr :text="inviteUrl" :size="180" :margin="10" style="width: 40%; height: 40%; margin-top: 15%;"></vue-qr>
			<label style="color: #FF0000; font-size: 18px; margin-top: 10px;">{{$t('home.copy')}}</label>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import vueQr from 'vue-qr'
	import { Toast } from 'mint-ui';
	import Header from '../../components/NavHeader.vue'

	export default {
		name: 'Invite',
		components: {
			vueQr,
			Header
		},
		data:() => ({
			inviteBg : {
				height: "100vh",
				width: "100%%",
				backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			qrBg : {
				height: "340px",
				width: "100%",
				// backgroundImage: "url(" + require("../../assets/qr-bg.png") + ")",
				// backgroundRepeat: "no-repeat",
				// backgroundPosition: "fixed",
				// backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			inviteUrl : window.location.origin + '/#/register?reference=' + JSON.parse(localStorage.getItem('userInfo')).username			
		}),
		methods: {
			copyUrl() {
				var domUrl = document.createElement("input");
				domUrl.value = this.$data.inviteUrl;
				domUrl.id = "creatDom";
				document.body.appendChild(domUrl);
				// 选择对象
				domUrl.select(); 
				// 执行浏览器复制命令
				document.execCommand("Copy"); 
				let creatDom = document.getElementById("creatDom");
				creatDom.parentNode.removeChild(creatDom);
				Toast({
					message: 'Success',
					position: 'bottom'
				});
			}
		}
	}

</script>

<style>
</style>
